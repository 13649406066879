.intercom-lightweight-app-launcher {
   bottom: 25px !important;
}

@media (min-width: 1921px) {
    .intercom-lightweight-app-launcher {
      bottom: 40px !important;
    }
  }

@media (max-width: 1366px) {
  .intercom-lightweight-app-launcher {
    bottom: 10px !important;
  }
}