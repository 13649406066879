$m-0: 0px !important;
$m-5: 5px !important;
$m-10: 10px !important;
$m-20: 20px !important;
$m-30: 30px !important;

$p-0: 0px !important;
$p-5: 5px !important;
$p-10: 10px !important;
$p-20: 20px !important;
$p-30: 30px !important;

/**margins*/
.m-auto { margin: auto !important;}
.m-0 { margin: $m-0;}
.m-5 { margin: $m-5;}
.m-10 { margin: $m-10;}
.m-20 { margin: $m-20;}
.m-30 { margin: $m-30;}

/**margin-top*/
.m-t-0 { margin-top: $m-0;}
.m-t-5 { margin-top: $m-5;}
.m-t-10 { margin-top: $m-10;}
.m-t-20 { margin-top: $m-20;}
.m-t-30 { margin-top: $m-30;}

/**margin-left*/
.m-l-0 { margin-left: $m-0;}
.m-l-5 { margin-left: $m-5;}
.m-l-10 { margin-left: $m-10;}
.m-l-20 { margin-left: $m-20;}
.m-l-30 { margin-left: $m-30;}

/**margin-bottom*/
.m-b-0 { margin-bottom: $m-0;}
.m-b-5 { margin-bottom: $m-5;}
.m-b-10 { margin-bottom: $m-10;}
.m-b-20 { margin-bottom: $m-20;}
.m-b-30 { margin-bottom: $m-30;}

/**margin-right*/
.m-r-0 { margin-right: $m-0;}
.m-r-5 { margin-right: $m-5;}
.m-r-10 {  margin-right: $m-10;}
.m-r-20 {  margin-right: $m-20;}
.m-r-30 {  margin-right: $m-30;}

/*paddings*/
.p-auto {  padding: auto !important;}
.p-0 { padding: $m-0;}
.p-5 { padding: $m-5;}
.p-10 { padding: $m-10;}
.p-20 { padding: $m-20;}
.p-30 { padding: $m-30;}

/**padding-top*/
.p-t-0 { padding-top: $m-0;}
.p-t-5 { padding-top: $m-5;}
.p-t-10 { padding-top: $m-10;}
.p-t-20 { padding-top: $m-20;}
.p-t-30 { padding-top: $m-30;}
/**padding-bottom*/
.p-b-0 {  padding-bottom: $m-0;}
.p-b-5 {  padding-bottom: $m-5;}
.p-b-10 {  padding-bottom: $m-10;}
.p-b-20 { padding-bottom: $m-20;}
.p-b-30 {  padding-bottom: $m-30;}

/**padding-left*/
.p-l-0 { padding-left: $m-0;}
.p-l-5 {  padding-left: $m-5;}
.p-l-10 {  padding-left: $m-10;}
.p-l-20 {  padding-left: $m-20;}
.p-l-30 {  padding-left: $m-30;}

/**padding-right*/
.p-r-0 {  padding-right: $m-0;}
.p-r-5 {  padding-right: $m-5;}
.p-r-10 {  padding-right: $m-10;}
.p-r-20 {  padding-right: $m-20;}
.p-r-30 {  padding-right: $m-30;}
