/*Dialog**/

/*background Dialog**/
.cdk-overlay-dark-backdrop {  background: rgb(0 0 0 / 80%) !important;  }
.cdk-overlay-pane { max-height: 83% !important;  min-height: max-content; }
.mat-dialog-actions {  padding-bottom: 20px !important; }
.mat-dialog-title {
  display: flex !important;
  align-items: center !important;
  gap: 0.5vw !important;
}
.mat-dialog-title > h2 {
  margin-bottom: 0px !important;
}
.itopia-light .mat-dialog-actions .mat-button {
  background-color:  $ligth-background;
}
.cdk-global-overlay-wrapper {
  pointer-events: auto !important; // be able to scroll event
  overflow-y: scroll !important;
  z-index: 1000 !important; // remove z-index
}
.cdk-overlay-pane {
  z-index: 1002 !important; // over the overlay
}

.itopia-light .mat-drawer-side.mat-drawer-end {
  background-color: $light-card-background !important;
}

/**scroll-bar-light*/
/* Works on Chrome, Edge, and Safari */
.mat-dialog-content::-webkit-scrollbar {
  width: 8px !important;
}

.itopia-light *::-webkit-scrollbar-track {
background: #dddde8 !important;
}

.itopia-light *::-webkit-scrollbar-thumb {
  background-color: #b2c1cf !important;
border-radius: 20px !important;
border: 2px solid #dddde8 !important;
}

/**scroll-bar-dark*/
/* Works on Chrome, Edge, and Safari */
.itopia-dark *::-webkit-scrollbar-track {
  background: #2d2f3a !important;
}

.itopia-dark *::-webkit-scrollbar-thumb {
  background-color: #606870 !important;
  border-radius: 20px !important;
  border: 2px solid #2d2f3a !important;
}

