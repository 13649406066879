.itopia-light .menu-container{
    background-color: $panel-light-background;
    border: none;
    min-height: 100%;
}
.itopia-light .mat-nav-list .mat-list-item:hover,
.itopia-light .mat-nav-list .mat-list-item:focus,
.itopia-light .mat-nav-list .mat-list-item.active
{
    color: $primary-color !important;
    font-weight: 500;
    min-width: 11.2vw;
    width: auto;
    border-right: solid 2px $primary-color !important;
}
.itopia-dark .mat-nav-list .mat-list-item:hover,
.itopia-dark .mat-nav-list .mat-list-item:focus,
.itopia-dark .mat-nav-list .mat-list-item.active
{
    color: $primary-color-dark !important;
    font-weight: 500;
    min-width: 11.2vw;
    width: auto;
    border-right: solid 2px $primary-color-dark !important;
}
.mat-list-item-content {
     font-size: 1rem;
   
 }
.menu-container {width: 12vw;}
